<template>
  <div class="channel"
      v-loading='data_running'>
    <content-title
      :title="$t('message.link')"
    ></content-title>
    
    <!-- 书籍列表 -->
    <div class="channel_list_box" v-if="channelArr.length > 0">
      <div class="channel_title flexCenter-ai-center">
        <span style="width: 41.6%;padding-left: 45px;" >{{$t('message.name')}}</span>
        <span style="width: 11.6%" class="flexCenter-column">{{$t('message.linkId')}}</span>
        <span style="width: 11.6%" class="flexCenter-column">{{$t('message.clicks')}}</span>
        <span style="width: 11.6%" class="flexCenter-column">{{$t('message.top-ups')}}</span>
        <span style="width: 11.6%" class="flexCenter-column">{{$t('message.amount')}}</span>
        <span style="width: 11.6%" class="flexCenter-column">{{$t('message.commission ($)')}}</span>
      </div>
      <div class="channel_list">
        <div class="channel_item flexCenter-ai-center" v-for="( item ) in channelArr" :key="item.id">
          <div class="channel_name flexCenter-ai-center" style="width: 41.6%; padding-left: 45px;">
            <div class="channel_left flex-column">
              <span class="name">{{item.title}}</span>
              <span class="link" @click="copy(item.link)">{{item.link}}</span>
              <span class="time">{{item.create_time}}</span>
            </div>
            <div class="copy flexCenter-ai-center" @click="copy(item.link)">
              <!-- <img src="@/assets/images/copy.png" alt=""> -->
            </div>
          </div>
          <span class="flexCenter-column" style="width: 11.6%">{{item.id}}</span>
          <span class="flexCenter-column" style="width: 11.6%">{{item.click_num}}</span>
          <span class="flexCenter-column" style="width: 11.6%">{{item.recharge_num}}</span>
          <span class="flexCenter-column" style="width: 11.6%">{{item.recharge_amount}}</span>
          <span class="flexCenter-column" style="width: 11.6%">{{item.commission}}</span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="sizeChange"
          :hide-on-single-page="true"
          :total="count"
          :page-size="data.limit"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty flexCenter flex-column" v-if="is_channels">
      <img src="@/assets/images/empty.png" alt="" />
      <span>{{ $t("message.noMoreData") }}</span>
    </div>

  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { promotionLink } from '@/apis/api'
export default {
  name: 'channel',
  data () {
    return {
      count: 100,
      data: {
        page: 1,
        limit: 20,
      },
      data_running: false,
      channelArr: [], // 渠道列表
      is_channels: false, // 是否加载无数据布局
    }
  },
  created () {
    this.promotionLink()
  },
  methods: {
    // 分页
    sizeChange(v) {
      this.data.page = v
      this.promotionLink()
    },
    // 复制
    copy(val) {
      var input = document.createElement("input");
      input.value = val;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.$message({
        message: this.$t('message.copied'),
        type: 'success'
      })
    },
    // 获取推广列表
    async promotionLink() {
      this.data_running = true
      let params = {
        page: this.data.page,
        limit: this.data.limit
      }
      let res = await promotionLink(params)
      this.data_running = false
      if (res.code === 1) {
        this.count = res.count
        this.channelArr = res.data
      }
      res.data.length > 0 ? this.is_channels = false : this.is_channels = true
      console.log(res)
    }
  },
  components: {
    contentTitle,
  },
}
</script>
<style lang="scss" scoped>
.channel {
  .channel_list_box {
    margin-top: 30px;
    border-radius: 10px;
    background: #ffffff;
    // height: 660px;
    .channel_title {
      height: 60px;
      > span {
        text-align: left;
        // padding-left: 45px;
        color: #15161a;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .channel_list {
      line-height: 30px;
      .channel_item {
        height: 100px;
        background-color: #fff;
        .channel_name {
          // padding-left: 45px;
          .channel_left {
            // flex: 1;
            max-width: calc(100% - 32px);
            .name {
              color: #32343A;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
            .link {
              cursor:pointer;
              color: #32343A;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin-top: 8px;
              margin-bottom: 4px;
              
              overflow:hidden; //超出的文本隐藏
              text-overflow:ellipsis; //溢出用省略号显示
              white-space:nowrap; //溢出不换行

              // max-width: calc(100% - 100px);
            }
            .link:hover {
              color: #000;
            }
            .time {
              color: #7D7D7D  ;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }
          }
          .copy {
            cursor:pointer;
            margin-left: 12px;
            background-image: url('../../assets/images/copy.png');
            background-size: 100% 100%;
            width: 18px;
            height: 18px;
            // img {
            //   width: 18px;
            //   height: 18px;
            // }
          }
          .copy:hover {
            background-image: url('../../assets/images/copy.1.png');
            background-size: 100% 100%;
          }
        }
        >span {
          color: #32343A;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //溢出用省略号显示
          white-space:nowrap; //溢出不换行
          padding: 0 10px;
        }
      }
      .channel_item:nth-child(odd) {
        background-color: #f9f9f9;
      }
      .channel_item:hover {
        background-color: #F3F6FF;
      }
    }

    .pagination {
      padding-bottom: 40px;
      margin-top: 30px;
    }
  }
  
  .empty {
    height: 500px;
    border-radius: 10px;
    background: #ffffff;
    margin-top: 30px;
    img {
      width: 140px;
    }
    span {
      color: #9497a1;
      font-size: 14px;
    }
  }
}
</style>
